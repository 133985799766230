import React from "react";
import {Link} from "gatsby";
import {makeStyles} from "@material-ui/core";

import MidSiteLogo from '../../assets/logo_midsite.svg';

// Scale height according to 16:9 aspect ratio, constraining by width as aspect ratio becomes too thin
const iconHeight = "min(25vh, 38.376vw)";
const backgroundSize = `calc(${iconHeight} * 0.8)`;
const offset = `calc(${iconHeight} / 2 * -1)`;

const useStyles = makeStyles({
  positionBox: {
    position: "relative", // Enable `absolute`

    // Horizontally center all content
    display: "flex",
    justifyContent: "center",

    // Overlap the page sections above and below
    marginTop: offset,
    marginBottom: offset,

    "& img": {
      marginBottom: 0,
    }
  },
  iconUnder: {
    height: iconHeight,
    position: "absolute", // Enable zIndex
    zIndex: 1,
    filter: `drop-shadow(0 calc(${iconHeight} * 0.04) calc(${iconHeight} * 0.08) #000000)`
  },
  colorBlock: {
    height: backgroundSize,
    width: backgroundSize,
    borderRadius: "50%", // Make circular to fit inside hexagon
    position: "absolute",
    zIndex: 2,
    top: `calc(${backgroundSize} / 8)`,
    backgroundColor: "#0E1118"
  },
  iconOver: {
    height: iconHeight,
    position: "relative", // Enable zIndex
    zIndex: 3
  }
});

/**
 * Inserts our company or product logo icon between two page sections, encouraging the user to scroll down.
 *
 * Does shadow and transparency fill in code, which adds complexity here, but means fewer asset file variants. To do
 * this, it layers as follows:
 * - The icon with a drop-shadow
 * - A circle of solid colour to block the transparent section of the logo
 * - A second icon with no drop-shadow
 *
 * We need the sandwiched icons because otherwise the drop-shadow would fall onto the colour block and ruin the effect.
 */
export const MidpageLogo = () => {
  const classes = useStyles();

  return (
    <div className={classes.positionBox}>
      <img src={MidSiteLogo} alt="Scroll down logo button" className={classes.iconUnder}/>
      <div className={classes.colorBlock}/>
      <Link to="/#solutions">
        <img src={MidSiteLogo} alt="Scroll down logo button" className={classes.iconOver}/>
      </Link>
    </div>
  );
}

export default MidpageLogo;
