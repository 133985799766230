import {Grid, makeStyles, Typography} from "@material-ui/core";
import {Link} from "gatsby";
import React from "react";
import Typist from 'react-typist';
import TypistLoop from 'react-typist-loop'
import SectionContainer from "./sectionContainer";

const useStyles = makeStyles({
  positionContainer: {
    height: "calc(100vh - 3.75rem - min(12.5vh, 19.188vw))", // Screen - header - icon
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  contentBlock: {
    //background: "rgba(14, 40, 71, .95)",
    //borderRadius: '0.5rem',
    textAlign: "center",
    fontSize: "min(1vw, 1.35vh)",
    width: "min(70vw, 124.444vh)",
    height:
      "min(" +
        "113.26vw," + // Golden ratio for tall and thin
        "min(76.91vh, 95%)" + // Short and fat golden ratio for 70vw at 16:9, or don't overflow container into header or icon
      ")",
    padding: "2.5em",
  },
  titleText: {
    lineHeight: 0.8,
    fontSize: "6.64em"
  },
  typewriterText: {
    lineHeight: 0.8,
    //color: "#0e56ab",
    fontSize: "7.9em"
  },
  linkText: {
    fontStyle: "italic",
    fontSize: "6em"
  }
});

export const LandingPage = (
  backgroundImage: string,
  title: string,
  descriptionText: string,
  learnMoreLink?: string,
  variableWords?: string[]
) => {
  const classes = useStyles();

  return (
    <SectionContainer backgroundImage={backgroundImage}>
      <div className={classes.positionContainer}>
        <Grid container
          alignItems="center"
          direction="column"
          justify="space-between"
          className={classes.contentBlock}
        >
          <Grid item>
            <Typography variant="h1" color="secondary" className={classes.titleText}>
              {title}
            </Typography>
          </Grid>
          <Grid item style={{height: "35%", display: "flex", alignItems: "center"}}>
            <TypistLoop interval={1000}>
              {(variableWords ?? []).map(text =>
                <Typist cursor={{show: false}} avgTypingDelay={25} key={text}>
                  <Typography variant="h1" color="secondary" className={classes.typewriterText}>
                    {text}
                  </Typography>
                </Typist>
              )}
            </TypistLoop>
          </Grid>

          {/** Group these items together to prevent the typewriter causing the text to bob up and down**/}
          <Grid item style={{fontSize: "min(1vw, 0.618vh)", background: "rgba(14, 40, 71, .95)", padding: "5em", width: "100%"}}>
            <Typography variant="h2" color="secondary" style={{fontSize: "6em"}}>
              {descriptionText}
            </Typography>
            <Grid container
              justify="space-around"
              style={{marginTop: "2.5em"}}
            >
              <Grid item style={{margin: "0 2.5em"}}>
                <Link to="/#contact">
                  <Typography variant="h3" className={classes.linkText}>
                    Contact Sales
                  </Typography>
                </Link>
              </Grid>
              <Grid item style={{margin: "0 2.5em"}}>
                <Link to={learnMoreLink ?? "/#technologies"}>
                  <Typography variant="h3" className={classes.linkText}>
                    {learnMoreLink ? "Learn more" : "Explore Technologies"}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </SectionContainer>
  );
}

export default LandingPage;
