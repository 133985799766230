import React from "react";
import {makeStyles, Typography} from "@material-ui/core";
import SectionContainer from "./sectionContainer";

const useStyles = makeStyles({
  aboutContent: {
    width: "70vw",
    fontSize: "min(1vw, 1vh)", // Begin shrinking text at 1:1 screen

    // Account for the header and the logo button above
    height: "calc(100vh - 3.75rem - min(12.5vh, 19.188vw))",

    // Anchor to the bottom of the section, out of the way of the logo button above
    position: "absolute",
    bottom: 0,

    // Center the text within
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  aboutText: {
    letterSpacing: ".005em",
    lineHeight: "1.5",
    fontSize: "4.5em",
    textShadow: "0px 0.05em 0.1em black"
  },
});

export const AboutSection = ({children} : {children: React.ReactNode}) => {
  const classes = useStyles();

  return (
    <SectionContainer>
      <div className={classes.aboutContent}>
        <Typography variant="h3" className={classes.aboutText}>
          {children}
        </Typography>
      </div>
    </SectionContainer>
  )
}

export default AboutSection;
