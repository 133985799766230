import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  topGradient: {
    background: "linear-gradient(180deg, rgba(14,17,24,1) 0%, rgba(14,17,24,0) 100%)",
    width: "100%",
    height: "20vh",

    // Overcome parent padding
    position: "absolute",
    top: 0,
    left: 0
  },
  bottomGradient: {
    background: "linear-gradient(180deg, rgba(0, 59, 109, 0) 0%, rgba(0, 59, 109, 1) 100%)",
    width: "100%",
    height: "20vh",

    // Overcome parent padding
    position: "absolute",
    bottom: 0,
    left: 0
  }
});

export const SectionContainer = (
  {children, backgroundImage, topGradient, bottomGradient} :
  {children?: React.ReactNode, backgroundImage?: string, topGradient?: boolean, bottomGradient?: boolean}
) => {
  const classes = useStyles();
  const adder = backgroundImage ?
    {backgroundImage: `url("${backgroundImage}")`, backgroundSize: "cover"} :
    {backgroundColor: "#0E1118"};

  return (
    <div style={{
      minHeight: "calc(100vh - 3.75rem)",
      position: "relative", // Needed for absolute among children

      // Horizontal centering of children
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      // Add background
      ...adder
    }}>
      {topGradient ? <div className={classes.topGradient}/> : null}
      {children}
      {bottomGradient ? <div className={classes.bottomGradient}/> : null}
    </div>
  )
}

export default SectionContainer;
