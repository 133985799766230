import {Grid, makeStyles, Typography} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  expertiseCell: {
    fontSize: "min(1vw, 0.57vh)",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    aspectRatio: "1 / 1.618",
    width: "70.18em",
    borderRadius: '2em',
    backgroundColor: "rgba(14, 40, 71, .90)",
    margin: "2.5em",
    padding: "2.5em",
    // Top graphic
    '& img': {
      height: '30em',
      margin: "5em auto"
    }
  },
  expertiseHeadline: {
    fontSize: "9em",
    marginBottom: "0.277em",
  },
  expertiseText: {
    fontSize: "2.86em",
    lineHeight: 1.21
  }
});

export const ProductBlock = ({children, title, icon} : {
  children: React.ReactNode
  title: string,
  icon?: string,
  }
) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.expertiseCell}>
      {icon ? <img src={icon} alt="product block icon"/> : null}
      <Typography
        variant="h2"
        color="secondary"
        className={classes.expertiseHeadline}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="secondary"
        className={classes.expertiseText}
      >
        {children}
      </Typography>
    </Grid>
  );
}

export default ProductBlock;
