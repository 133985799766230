import React from 'react';
import {Carousel, CarouselItem} from "react-bootstrap";
import LandingPage from './landingPage';

import CircuitBoardPhoto from '../../assets/landing/slider_sol_72dpi_201117.png';
import AIPhoto from '../../assets/landing/slider_ai_72dpi_201118.png';
import RegexPhoto from '../../assets/landing/slider_regex_72dpi_201117.png';

/**
 * Carousel containing the topmost and entire first page
 */
export const Slider = ({startPage}: {startPage?: number}) => {
  return (
    <Carousel
      interval={startPage ? null : 8000}
      indicators={false}
      defaultActiveIndex={startPage ?? 0}
      prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" style={{height: "4vmin", width: "4vmin"}}/>}
      nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" style={{height: "4vmin", width: "4vmin"}}/>}
    >
      <CarouselItem>
        {LandingPage(
          CircuitBoardPhoto,
          "When you want the fastest",
          "We help you get the most out of your compute resources",
          "/#learn",
          ["Development", "Item 2", "Item 3"]
        )}
      </CarouselItem>
      <CarouselItem>
        {LandingPage(
          AIPhoto,
          "When you want the fastest",
          "We help you get the most out of your",
          "/ai#learn",
          ["AI investment", " AI resources", " AI development"]
        )}
      </CarouselItem>
      <CarouselItem>
        {LandingPage(
          RegexPhoto,
          "When you want the fastest",
          "We have developed the best Regular Expression library.\n\n" +
          "We beat PCRE2 by a (wide) margin; we run on GPUs.\n",
          "/regex#learn",
          ["Textual Searching", "Intelligent Keyword Analysis"]
        )}
      </CarouselItem>
    </Carousel>
  );
}

export default Slider;
